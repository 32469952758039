import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    meta: {
      title: "Home", // 首页
    },
    path: '/',
    name: 'home',
    component: () => import("@/views/index.vue"),
    redirect: '/dashboard',
    children: [
      {
        meta: {
          title: "Dashboard", // 工作台
          icon: require('@/assets/images/nav/nav1.png'),
          selectIcon: require('@/assets/images/nav/nav1_on.png'),
        },
        path: '/dashboard',
        component: () => import("@/views/dashboard/dashboard.vue"),
      },
      {
        meta: {
          title: "Job List", // 工作列表
          icon: require('@/assets/images/nav/nav2.png'),
          selectIcon: require('@/assets/images/nav/nav2_on.png'),
        },
        path: '/job',
        redirect: '/job/job_list',
        children: [
          {
            meta: {
              title: "Job List", // 工作列表
              icon: "menu-home",
              hidden: true,
            },
            path: '/job/job_list',
            component: () => import("@/views/job_list/job_list.vue"),
          },
          {
            meta: {
              title: "Job Details", // 订单详情
              icon: "menu-home",
              hidden: true,
            },
            path: '/job/job_dropbox',
            component: () => import("@/views/job_list/job_dropbox.vue"),
          },
        ]
      },
      {
        meta: {
          title: "Bookings", // 日历
          icon: require('@/assets/images/nav/nav3.png'),
          selectIcon: require('@/assets/images/nav/nav3_on.png'),
        },
        path: '/calendar',
        children: [
          {
            meta: {
              title: "TBC", // tbc
              icon: require('@/assets/images/nav/nav3-1.png'),
              iconB: require('@/assets/images/nav/nav3-1_b.png'),
              selectIcon: require('@/assets/images/nav/nav3-1_on.png'),
              name: 'tbc'
            },
            name: 'tbc',
            path: '/calendar/tbc',
            component: () => import("@/views/calendar/tbc.vue"),
            redirect: '/calendar/booking_list',
            children: [
              {
                meta: {
                  title: "TBC List", // tbc
                  icon: "menu-home",
                  hidden: true,
                },
                path: '/calendar/booking_list',
                component: () => import("@/views/calendar/booking_list.vue"),
              },
              {
                meta: {
                  title: "Booking Info", // tbc
                  icon: "menu-home",
                  hidden: true,
                },
                path: '/calendar/booking_info',
                component: () => import("@/views/calendar/booking_info.vue"),
              },
              {
                meta: {
                  title: "Edit Booking Info", // tbc
                  icon: "menu-home",
                  hidden: true,
                },
                path: '/calendar/edit_booking_info',
                component: () => import("@/views/calendar/edit_booking.vue"),
              },
            ]
          },
          {
            meta: {
              title: "Create New", // tbc
              icon: require('@/assets/images/nav/nav3-2.png'),
              iconB: require('@/assets/images/nav/nav3-2_b.png'),
              selectIcon: require('@/assets/images/nav/nav3-2_on.png'),
            },
            path: '/calendar/create_new',
            component: () => import("@/views/calendar/create_new.vue"),
          },
        ],
      },
      {
        meta: {
          title: "Clients", // 客户
          icon: require('@/assets/images/nav/nav4.png'),
          selectIcon: require('@/assets/images/nav/nav4_on.png'),
        },
        path: '/clients',
        redirect: '/clients',
        children: [
          {
            meta: {
              title: "Clients", // 客户
              hidden: true,
            },
            path: '/clients',
            component: () => import("@/views/clients/clients.vue"),
          },
          {
            meta: {
              title: "Details", // 客户详情
              hidden: true,
            },
            path: '/clients/details',
            component: () => import("@/views/clients/clients_details.vue"),
          },
          {
            meta: {
              title: "Edit",
              hidden: true,
            },
            path: '/clients/edit',
            component: () => import("@/views/clients/clients_edit.vue"),
          },
          {
            meta: {
              title: "Price list",
              hidden: true,
            },
            path: '/clients/price_list',
            component: () => import("@/views/clients/clients_price_list.vue"),
          },
        ]
      },
      {
        meta: {
          title: "Employees", // 员工
          icon: require('@/assets/images/nav/nav5.png'),
          selectIcon: require('@/assets/images/nav/nav5_on.png'),
        },
        path: '/employees',
        redirect: '/employees',
        children: [
          {
            meta: {
              title: "Employees", // 员工
              hidden: true,
            },
            path: '/employees',
            component: () => import("@/views/employees/employees.vue"),
          },
          {
            meta: {
              title: "Details",
              hidden: true,
            },
            path: '/employees/details',
            component: () => import("@/views/employees/employees_details.vue"),
          },
          {
            meta: {
              title: "Edit",
              hidden: true,
            },
            path: '/employees/edit',
            component: () => import("@/views/employees/employees_edit.vue"),
          },
        ]
      },
      // {
      //   meta: {
      //     title: "Suppliers", // 供应商
      //     icon: require('@/assets/images/nav/nav5.png'),
      //     selectIcon: require('@/assets/images/nav/nav5_on.png'),
      //   },
      //   path: '/suppliers',
      //   component: () => import("@/views/suppliers/suppliers.vue"),
      // },
      {
        meta: {
          title: "Resources", // 资源
          icon: require('@/assets/images/nav/nav6.png'),
          selectIcon: require('@/assets/images/nav/nav6_on.png'),
        },
        path: '/resources',
        // component: () => import("@/views/resources/resources.vue"),
        component: () => import("@/views/suppliers/suppliers.vue"),
      },
      {
        meta: {
          title: "Invoices", // 发票
          icon: require('@/assets/images/nav/nav7.png'),
          selectIcon: require('@/assets/images/nav/nav7_on.png'),
        },
        path: '/invoices',
        redirect: '/invoices',
        children: [
          {
            meta: {
              title: "Invoices", // 发票
              hidden: true,
            },
            path: '/invoices',
            component: () => import("@/views/invoices/invoices.vue"),
          },
          {
            meta: {
              title: "Archive Invoices",
              hidden: true,
            },
            path: '/invoices/archive_invoices',
            component: () => import("@/views/invoices/archive_invoices.vue"),
          },
          {
            meta: {
              title: "Clients Invoices",
              hidden: true,
            },
            path: '/invoices/list',
            component: () => import("@/views/invoices/clients_invoices.vue"),
          },
          {
            meta: {
              title: "Invoices details",
              hidden: true,
            },
            path: '/invoices/details',
            component: () => import("@/views/invoices/clients_invoices_details.vue"),
          },
          {
            meta: {
              title: "Invoices Edit",
              hidden: true,
            },
            path: '/invoices/edit',
            component: () => import("@/views/invoices/edit_invoices.vue"),
          },
        ]
      },
      // {
      //   meta: {
      //     title: "Insights", // 洞察
      //     icon: require('@/assets/images/nav/nav9.png'),
      //     selectIcon: require('@/assets/images/nav/nav9_on.png'),
      //   },
      //   path: '/insights',
      //   component: () => import("@/views/insights/insights.vue"),
      // },
      {
        meta: {
          title: "Settings", // 设置
          icon: require('@/assets/images/nav/nav10.png'),
          selectIcon: require('@/assets/images/nav/nav10_on.png'),
        },
        path: '/settings',
        children: [
          {
            meta: {
              title: "Company", // 公司
              icon: require('@/assets/images/nav/nav10-1.png'),
              iconB: require('@/assets/images/nav/nav10-1_b.png'),
              selectIcon: require('@/assets/images/nav/nav10-1_on.png'),
            },
            path: '/settings/company',
            component: () => import("@/views/settings/company.vue"),
          },
          {
            meta: {
              title: "Products", //服务产品
              icon: require('@/assets/images/nav/nav10-2.png'),
              iconB: require('@/assets/images/nav/nav10-2_b.png'),
              selectIcon: require('@/assets/images/nav/nav10-2_on.png'),
            },
            path: '/settings/products',
            // component: () => import("@/views/settings/products.vue"),
            redirect: '/settings/products',
            children: [
              {
                meta: {
                  title: "Products", // 服务
                  icon: "menu-home",
                },
                path: '/settings/products',
                component: () => import("@/views/settings/products/products.vue"),
              },
            ]
          },
          {
            meta: {
              title: "Services & Price", //服务产品
              icon: require('@/assets/images/nav/nav10-3.png'),
              iconB: require('@/assets/images/nav/nav10-3_b.png'),
              selectIcon: require('@/assets/images/nav/nav10-3_on.png'),
            },
            path: '/settings/products_template',
            redirect: '/settings/products_template',
            children: [
              {
                meta: {
                  title: "Products Template", // 服务模板
                  icon: "menu-home",
                },
                path: '/settings/products_template',
                component: () => import("@/views/settings/products/products_template.vue"),
              },
              {
                meta: {
                  title: "Products", // 服务模板
                  icon: "menu-home",
                },
                path: '/settings/template_details',
                component: () => import("@/views/settings/products/template_details.vue"),
              },
            ]
          },
          {
            meta: {
              title: "Price-list", // 价格列表
              icon: require('@/assets/images/nav/nav10-3.png'),
              iconB: require('@/assets/images/nav/nav10-3_b.png'),
              selectIcon: require('@/assets/images/nav/nav10-3_on.png'),
            },
            path: '/settings/price_list',
            component: () => import("@/views/settings/price_list.vue"),
          },
          // {
          //   meta: {
          //     title: "Invoice", // 设置-发票
          //     icon: require('@/assets/images/nav/nav10-4.png'),
          //     iconB: require('@/assets/images/nav/nav10-4_b.png'),
          //     selectIcon: require('@/assets/images/nav/nav10-4_on.png'),
          //   },
          //   path: '/settings/invoice',
          //   component: () => import("@/views/settings/invoice.vue"),
          // },
          {
            meta: {
              title: "Billing", // 财务
              icon: require('@/assets/images/nav/nav10-5.png'),
              iconB: require('@/assets/images/nav/nav10-5_b.png'),
              selectIcon: require('@/assets/images/nav/nav10-5_on.png'),
            },
            path: '/settings/billing',
            component: () => import("@/views/settings/billing.vue"),
          },
        ]
      },
    ],
  },
  {
    meta: {
      title: "Login", // 登录页
      hidden: true,
    },
    path: '/login',
    component: () => import("@/views/login/login.vue"),
  },
  {
    meta: {
      title: "Share Calendar", // 登录页
      hidden: true,
    },
    path: '/share_calendar',
    component: () => import("@/views/share_calendar/share_calendar.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
